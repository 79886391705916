function add0(m) {
  return m < 10 ? "0" + m : m;
}
export function formartDate(timestamp, type, week) {
  var time = new Date(timestamp);
  if (!timestamp) {
    return "";
  }
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var w = time.getDay();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  var result = "";
  var numberChinese = "日一二三四五六";
  // console.log(type);
  switch (type) {
    case "year":
      result = y;
      break;
    case "month":
      result = y + "-" + add0(m);
      break;
    case "day":
      result = y + "-" + add0(m) + "-" + add0(d);
      break;
    case "date":
      result = y + "/" + add0(m) + "/" + add0(d);
      break;
    case "hour":
      result = y + "-" + add0(m) + "-" + add0(d) + " " + add0(h);
      break;
    case "minutes":
      result =
        y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm);
      break;
    case "seconds":
      result =
        y +
        "-" +
        add0(m) +
        "-" +
        add0(d) +
        " " +
        add0(h) +
        ":" +
        add0(mm) +
        ":" +
        add0(s);
      break;
    case "h-m-s":
      result = add0(h) + ":" + add0(mm) + ":" + add0(s);
      break;
    case "h-m":
      result = add0(h) + ":" + add0(mm);
      break;
    case "all":
      result =
        y +
        "-" +
        add0(m) +
        "-" +
        add0(d) +
        " " +
        add0(h) +
        ":" +
        add0(mm) +
        ":" +
        add0(s);
      break;
    case "allweek":
      result =
        y +
        "-" +
        add0(m) +
        "-" +
        add0(d) +
        " 星期" +
        numberChinese[w] +
        " " +
        add0(h) +
        ":" +
        add0(mm) +
        ":" +
        add0(s);
      break;
    default:
      result =
        y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm);
      break;
  }
  if (week) {
    result += " 星期" + numberChinese[w];
  }
  return result;
}
export function getFileType(type) {
  if ("doc,docx,DOCX,DOC".indexOf(type) !== -1) {
    // console.log('word文档')
    return "word.png";
  } else if ("xls,xlsx,XLSX,XLS".indexOf(type) !== -1) {
    // console.log('Excel文档')
    return "excel.png";
  } else if ("ppt,pptx,PPTX,PPT".indexOf(type) !== -1) {
    // console.log('Excel文档')
    return "ppt.png";
  } else if ("jpg,jpeg,png,gif,JPG,JPEG,GIF".indexOf(type) !== -1) {
    // console.log('图片')
    return "picture.png";
  } else if ("pdf,PDF".indexOf(type) !== -1) {
    // console.log('pdf文件')
    return "pdf.png";
  } else {
    // console.log('默认')
    return "word.png";
  }
}

// export const openWindow = url => {
//   const aTag = document.createElement("a");
//   aTag.href = url;
//   aTag.target = "_blank";
//   aTag.click();
// };
